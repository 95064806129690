import React  from 'react';
import {useState,useContext, useEffect} from 'react'
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import Redirecting from '../../components/Redirecting';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import DataCheck from '../../pages/identification/DataCheck';
import OptionsFailed from '../../pages/identification/OptionsFailed';
import ExtraForm from '../../pages/identification/ExtraForm';
export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });

  const Result = () => {
    const { user } = useContext(UserContext); // Removed `text` if it's not being used
    const [isLoading, setIsLoading] = useState(true); // Start in loading state
    const [result, setResult] = useState(null); // Initialize result as null to check later

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                
                // Simulate a delay of 10 seconds (10000 milliseconds)
                await new Promise(resolve => setTimeout(resolve, 10000));
    
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
                
                const { data } = await Axios.post('user/get_id_status.php', {
                    user_id: user.user_id
                });
                
                setResult(data); // Set result with fetched data
            } catch (err) {
                setResult({ success: 0, message: 'Server Error!' }); // Handle error case
            } finally {
                setIsLoading(false); // End loading state
            }
        };
    
        fetchData();
    }, [user.user_id]); // Add user.user_id as dependency
    
    // Handle loading state
    if (isLoading) {
        return <Redirecting />; // Display a loading spinner or message
    }

    // Check if result is loaded and valid
    if (!result || !result.user) {
        return <div>Error: Unable to load data.</div>; // Handle error or missing data
    }

    return (
        <div id="dashboard">
            <Topbar />   
            <Sidebar /> 
            <div className="board">
                <div className="card card-large">
                    <div className="card-body">
                        {/* Render the result based on the user data */}
                        {result.user.id_progress === 'COMPLETED' ? (
                            <DataCheck result={result} />
                        )  : (
                            <OptionsFailed result={result} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Result;