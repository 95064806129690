import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import Onboarding from '../pages/home/Onboarding';
import Overview from '../pages/home/Overview';
import InboxItem from '../pages/home/InboxItem';
import OpenActions from '../pages/home/OpenActions';
import UpdatePreview from '../pages/home/UpdatePreview';
import {useContext} from 'react'
import {UserContext} from '../context/UserContext'
import React from "react";



const Home = () => {

    const {user, profile} = useContext(UserContext);
    let isOpenActionsRendered = false;

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
            <div className="board preview">
            <div className="board">
  
        {user.has_investments === 'no'
        ? <Onboarding/>
        : <Overview/>
        }
      

 
        
        {profile !== 'none' && profile.map((profile, index) => {
    if ((user.open_actions === 'yes') && !isOpenActionsRendered) {
        isOpenActionsRendered = true;
        return <OpenActions key={index} />;
    }
    return null;
})}
        

        {user.participated && user.participated === 'yes' && (
            <UpdatePreview/>
        )}

        

</div>

<InboxItem/> 

    </div>  
            
        </div>

    )
}

export default Home;
