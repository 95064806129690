import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import React from "react";
import {useState,useContext,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../context/UserContext'
import Questionnaire from './invest/Profile';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Profile from './invest/Profile';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Investment from './invest/Investment';
import FinalChecks from './invest/FinalChecks';
import Signature from './invest/Signature';
import Modal from 'react-bootstrap/Modal';
import PaymentResponse from './invest/PaymentResponse';
import Transaction from './invest/Transaction';
import Redirecting from '../components/Redirecting';
import axios from 'axios'
export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });






const Invest = () => {

    const {user, terms, loggedInCheck, profile} = useContext(UserContext);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [wait, setWait] = useState(false);
    const [url, setUrl] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [Exists, setExists] = useState(false);

    /* Set current tab based on local storage */

    const localProgress = localStorage.getItem('setProgress');

    const [currentTab, setCurrentTab] = useState(localProgress || 'profile');

    const [progressTab, setProgressTab] = useState();

    /* Define selected profile */
    
    const selectedProfile = localStorage.getItem('selectedProfile');

    const [enableState, setEnableState] = useState({selectedProfile})
    
    useEffect(() => {
        if (localStorage.getItem('selectedProfile')) {
            setEnableState(!{selectedProfile});
            } else {
                setEnableState({selectedProfile});
            }
      }, [selectedProfile]);

      const onProfileChange=(newValue)=>{
        localStorage.setItem('selectedProfile', newValue);
        localStorage.setItem('selectedMethod', 'regular');
        if ({selectedProfile}) {
        setEnableState(!{selectedProfile});
        } else {
        setEnableState({selectedProfile}); 
        }
      }

    /* Define selected method */
    
    const selectedMethod = localStorage.getItem('selectedMethod');

    

    const [enableMethod, setEnableMethod] = useState({selectedMethod})

    useEffect(() => {

      // Get the URL search parameters
      const params = new URLSearchParams(window.location.search);

      // Check if the 'id' parameter exists in the URL
      if (params.has('id')) {
        
        

        // Find the profile's investment by matching `investment_id_ext`
            const foundInvestment = profile
            ?.flatMap(profile => profile.investments)
            .find(investment => investment.investment_id_ext === params.get('id'));

        if (foundInvestment) {
            // Get relevant data from the matched investment
           
            const { pension, recurring, orders } = foundInvestment;
            const orderAmount = orders[0]?.order_amount || 0;
            if (pension === 1) {
              localStorage.setItem('selectedMethod', 'pension');
            } else {
              localStorage.setItem('selectedMethod', 'regular');
            }
            if (recurring === 1) {
              localStorage.setItem('selectedRecurring', 'yes');
            } else {
              localStorage.setItem('selectedRecurring', 'no');
            }
            localStorage.setItem('setAmount', orderAmount);

            setCurrentTab("finalchecks");
            localStorage.setItem('setProgress', 'finalchecks');
            setProgressTab(2);
            setExists(true);
        }


      } else {
         localStorage.setItem('setProgress', 'profile');
      }
        if (localStorage.getItem('selectedMethod')) {
          setEnableMethod(!{selectedMethod});
            } else {
              setEnableMethod({selectedMethod});
            }
      }, [selectedMethod]);

      const onMethodChange=(newValue)=>{
        localStorage.setItem('selectedMethod', newValue);
        
        if ({selectedMethod}) {
          setEnableMethod(!{selectedMethod});
        } else {
          setEnableMethod({selectedMethod}); 
        }
      }


    /* Define set amount */

    const setAmount = localStorage.getItem('setAmount');

    const minSetAmount = localStorage.getItem('minSetAmount');

    const [typeAmount, setTypeAmount] = useState('')

    const [minimumAmount, setMinimumAmount] = useState(true)

    const handleSuccess = () => {
      setCurrentTab("transaction");
      localStorage.setItem('setProgress', 'transaction');
      setProgressTab(4);
    };

    const onAmountChange=(newAmount, minAmount)=>{
      
        const formattedAmount = parseFloat(newAmount.replace(/[.]/g, ''));
      
        localStorage.setItem('setAmount', formattedAmount);
        localStorage.setItem('minSetAmount', minAmount);
        
        setTypeAmount(newAmount);
        if (formattedAmount != null && formattedAmount >= minAmount && setAmount > 0) {
            setMinimumAmount(false)
        } else {
            setMinimumAmount(true)
        }

              // Add the following function to pass as a prop to the child component
      
      
      }
      const handleRecurringChange = (value) => {
        // Call setMinimumAmount(true) when handleRecurring is triggered in the child
        setMinimumAmount(true);
      }


      const handleClose = () => {
        localStorage.removeItem('setProgress');
        return '/';
      }


      /* see if all control boxes are checked*/

      const [allChecked, setAllChecked] = useState(false);

      const [profileIsChecked, setProfileIsChecked] = useState(false);

  const handleProfile = () => {
    setProfileIsChecked(!profileIsChecked);
  };

  const [methodIsChecked, setMethodIsChecked] = useState(false);

  const handleMethod = () => {
    setMethodIsChecked(!methodIsChecked);
  };

  const [amountIsChecked, setAmountIsChecked] = useState(false);

  const handleAmount = () => {
    setAmountIsChecked(!amountIsChecked);
  };

  const [contractIsChecked, setContractIsChecked] = useState(false);

  const handleContract = () => {
    setContractIsChecked(!contractIsChecked);
  };

  useEffect(() => {
    if (terms[0]?.status === 'agreed') {
      setContractIsChecked(true);
    }
    if (contractIsChecked && amountIsChecked && profileIsChecked && methodIsChecked) {
        setAllChecked(false)
    } else {
        setAllChecked(true)
    }
  });


    /* Create order */

    const handleCreateOrder = async (e) => {
        e.preventDefault();
        setWait(true);
        const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          setValidated(true);
      
        if(!Object.values(formData).every(val => val.trim() !== '')){
            
            return;
        }
      
        const data = await createOrder(formData);
        if(data.success){
            e.target.reset();
            
            
        }
        else if(!data.success && data.message){
            
        }
        
      }

     

      const [payment, setPayment] = useState(null);
      
      const createOrder = async () => {
    
        
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
           
            const {data} = await Axios.post('investment/create.php',{
              user_id: user.user_id,
              profile_id: localStorage.getItem('selectedProfile'),
              amount: localStorage.getItem('brutAmount'),
              pension: localStorage.getItem('selectedMethod'),
              recurring: localStorage.getItem('selectedRecurring'),
              flow_version: 'terms',
              
    
            }).then(response => response.data).then((d) => {
              setPayment(d);
              setCurrentTab("signature");
              localStorage.setItem('setProgress', 'signature');
              setProgressTab(3);
              //setUrl(d);
              //window.location.replace(d.Signers[0].SignUrl); // Move the redirect code here
            });
        
          localStorage.removeItem('setAmount');
          localStorage.removeItem('minSetAmount');
          localStorage.removeItem('selectedMethod');
          localStorage.removeItem('selectedProfile');
          localStorage.removeItem('selectedRecurring');
        
          
        
            
            
           // setErrMsg(false);

           
            
      
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
      
        
      }

      if (isLoading) {
        return <Redirecting />;
      }
    
      /* Set tabs and save */

    const toIdentification = (eventKey) => {
    setCurrentTab("identification");
    localStorage.setItem('setProgress', 'identification');
    };

    const toProfile = (eventKey) => {
      setCurrentTab("profile");
      localStorage.setItem('setProgress', 'profile');
      setProgressTab(0);
    };

  const toInvestment = (eventKey) => {
    setCurrentTab("investment");
    localStorage.setItem('setProgress', 'investment');
    localStorage.setItem('selectedRecurring', 'no');
    setProgressTab(1);
  };

  const toFinalChecks = (eventKey) => {
    setCurrentTab("finalchecks");
    localStorage.setItem('setProgress', 'finalchecks');
    setProgressTab(2);
  };

  const toSign = (eventKey) => {
    setCurrentTab("signature");
    localStorage.setItem('setProgress', 'signature');
    setProgressTab(3);
  };

  const toTransaction = (eventKey) => {
    setCurrentTab("transaction");
    localStorage.setItem('setProgress', 'transaction');
    setProgressTab(4);
  };

  const progressPercentage = (progressTab / 5) * 100;

  const getIconClass = (step) => {
    switch (step) {
        case 0:
            return 'bi-person';
        case 1:
            return 'bi-sliders2-vertical';
        case 2:
            return 'bi-list-check';
        case 3:
            return 'bi-patch-check';
        case 4:
            return 'bi-credit-card';
        default:
            return 'bi-circle';
    }
};

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
    
            <div className="board">

            <Card className="flowvest">
            <div className="progress-icons-container d-flex align-items-center justify-content-between">
                {[0, 1, 2, 3, 4].map((step) => (
                    <React.Fragment key={step}>
                        {/* Step Icon */}
                        <div
                            className={`icon-container ${progressTab >= step ? 'completed' : ''}`}
                        >
                            <i className={`bi ${getIconClass(step)}`} />
                        </div>

                        {/* Progress Bar (not shown after the last icon) */}
                        {step < 4 && (
                            <div
                                className={`progress-bar-container ${
                                  progressTab > step ? 'filled' : ''
                                }`}
                            >
                                <div className="progress-bar"></div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </Card>

            <Tabs
                defaultActiveKey="profile"
                activeKey={currentTab}
                id=""
                className="min-w-100 invest-flow"
                onSelect={(key) => setCurrentTab(key)}
                >
                    
                 <Tab eventKey="profile" title={<span>{progressTab > 0 ? <i className="bi checked step-icon bi-person"></i> : <i class="bi step-icon bi-person"></i>}</span>} disabled>
                    <div className="card card-full">
                        <div className="card-body">
                            <Profile onProfileChange={onProfileChange} /> 
                            <div className="card-btn d-flex">
                            
                            <button
                            onClick={toInvestment}
                            className="btn btn-primary" 
                            disabled={enableState}
                            >
                            ga verder met de belegging
                            </button>
                            </div>

                        </div>
                    </div>
                </Tab>
               
                <Tab eventKey="investment" title={<span>{progressTab > 1 ? <i className="bi checked step-icon bi-gear"></i> : <i class="bi step-icon bi-gear"></i>}</span>} disabled>
                <div className="card card-full">
                        <div className="card-body">
                        <Investment onRecurringChange={handleRecurringChange} onAmountChange={onAmountChange} onMethodChange={onMethodChange} /> 
                            <div className="card-btn d-flex">
                            <button
                            onClick={toProfile}
                            className="btn btn-link"
                            
                            >
                            Vorige
                            </button>
                            <button
                            onClick={toFinalChecks}
                            className="btn btn-primary"
                            disabled={minimumAmount}
                            >
                            Naar ondertekenen
                            </button>
                            </div>

                        </div>
                    </div>
                
                </Tab>
                <Tab eventKey="finalchecks" title={<span>{progressTab > 2 ? <i className="bi checked step-icon bi-list-check"></i> : <i class="bi step-icon bi-list-check"></i>}</span>} disabled>
                <div className="card card-full">
                        <div className="card-body">
                        {selectedMethod && (
                        <FinalChecks 
                        profileIsChecked={profileIsChecked} 
                        handleProfile={handleProfile}
                        handleMethod={handleMethod}
                        methodIsChecked={methodIsChecked}
                        amountIsChecked={amountIsChecked} 
                        handleAmount={handleAmount} 
                        contractIsChecked={contractIsChecked} 
                        handleContract={handleContract} 
                        setContractIsChecked={setContractIsChecked} /> 
                      )}
                         {allChecked && (
                            <Alert key='warning' variant='warning' className=" d-block fw mt-4">
                              Let op! Selecteer alle bovenstaande checkboxes om over te kunnen gaan tot ondertekening. Mocht er iets niet kloppen, kunt u uw voorkeuren wijzigen bij de voorgaande stappen. Beleggen volgens aanvullende voorwaarden lijfrente kan uitsluitend met een persoonlijk profiel. Lees hier meer over <Alert.Link target="_blank" href="https://capler.nl/files/documents.php?doc=annuity">aanvullende voorwaarden lijfrente.</Alert.Link> 
                            </Alert>
                            )}
                            <div className="card-btn d-flex">
                            {!Exists && (
                            <button
                            onClick={toInvestment}
                            className="btn btn-link"
                            
                            >
                            Vorige
                            </button>
                            )}
                            <Form className="large noinput" onSubmit={handleCreateOrder}>
                           
                            <button
                            className="btn btn-primary" 
                            type="submit"
                            disabled={allChecked}
                            >
                            Verder naar ondertekening
                            </button>
                            </Form>

                           

                            </div>

                        </div>
                    </div>
                </Tab>
                <Tab eventKey="signature" title={<span>{progressTab > 3 ? <i className="bi checked step-icon bi-vector-pen"></i> : <i class="bi step-icon bi-vector-pen"></i>}</span>} disabled>
                <div className="card card-full">
                        <div className="card-body">
                        <Signature onSuccess={handleSuccess} />
                        
                        </div>
                        </div>
                </Tab>
                <Tab eventKey="transaction" title={<span>{progressTab > 4 ? <i className="bi checked step-icon bi-credit-card"></i> : <i class="bi step-icon bi-credit-card"></i>}</span>} disabled>
                <div className="card card-full">
                        <div className="card-body">
                        <Transaction payment={payment}  />
                       
                        </div>
                        </div>
                </Tab>
            </Tabs>

            
            </div>
            
            
        </div>

    )
}

export default Invest;
