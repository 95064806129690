import React  from 'react';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Navbar from './Navbar';
import axios from 'axios';

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
});

const Twofactor = () => {
    const { user, loggedInCheck, text } = useContext(UserContext);
    const [successMsg, setSuccessMsg] = useState(false);
    const [wait, setWait] = useState(false);
    const [tfaEnabled, setTfaEnabled] = useState(user.tfa_enabled === '1');  // Initialize with the user's current value
    const navigate = useNavigate();

    // This useEffect keeps the formData in sync with tfaEnabled
    const [formData, setFormData] = useState({
        tfa_enabled: tfaEnabled ? 1 : 0,
    });

    // Sync formData whenever tfaEnabled is toggled
    useEffect(() => {
        setFormData({
            tfa_enabled: tfaEnabled ? 1 : 0
        });
    }, [tfaEnabled]);

    const onChangeInput = (e) => {
        // Toggle the checkbox value
        setTfaEnabled(e.target.checked);
    };

    const updateTfa = async (e) => {
        e.preventDefault();
        setWait(true);
        try {
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
            const { data } = await Axios.post('tfa/update.php', {
              user_id: user.user_id,
              tfa_enabled: formData.tfa_enabled
            });
            
            await loggedInCheck();  // Refresh user state after update
            setSuccessMsg('Tweestapsverificatie bijgewerkt!');
            setWait(false);
        } catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    };

    return (
        <div id="dashboard">
            <Topbar />   
            <Sidebar /> 
            <div className="board settingsy">
                <Navbar />
                <div className="card card-large settings">
                    <div className="card-body">
                        <h6 className="text-left small mb-3 muted">{text.settings}</h6>
                        <h4 className="text-left">Tweestapsverificatie</h4>
                        <p className="mb-3">
                            Activeer extra beveiliging om in te loggen. Naast je wachtwoord moet je een eenmalige code invoeren.
                        </p>
                        <Form className="extra-large mt-5" onSubmit={updateTfa}>
                            <Row>
                                <Col>
                                    <div className="form-switch">
                                        <input
                                            onChange={onChangeInput}
                                            checked={tfaEnabled}  // Bind to tfaEnabled state
                                            name="tfa_enabled"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                        />
                                        <label className="form-check-label ml-2" htmlFor="flexSwitchCheckDefault">
                                            Activeer tweestapsverificatie (e-mail)
                                        </label>
                                    </div>
                                </Col>
                            </Row>
      
                            <button className="btn btn-sm" 
                                type="submit"
                                disabled={wait}
                            >
                                Wijzigingen opslaan
                            </button>
                            {successMsg && <div className="success-msg mb-0">{successMsg}</div>}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Twofactor;