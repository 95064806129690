import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

const Overview = () => {

    const {text, profile} = useContext(UserContext);

    const sum = profile.reduce((a,v) =>  a = a + v.profile_total_amount , 0 );

    const orderedAcceptedValue = profile.reduce((accumulator, profile) => { 
        return accumulator + profile.profile_ordered_accepted;
      }, 0);

    const totalValue = profile.reduce((accumulator, profile) => { 
        return accumulator + profile.profile_value_amount;
      }, 0);

      const totalAccepted = profile.reduce((accumulator, profile) => { 
        return accumulator + profile.profile_accepted_amount;
      }, 0);

      const totalReturn = profile.reduce((accumulator, profile) => { 
        return accumulator + profile.profile_total_return;
      }, 0);

      const totalOrdered = profile.reduce((accumulator, profile) => { 
        return accumulator + profile.profile_ordered_amount;
      }, 0);

      const totalBrutReturn = totalValue - totalAccepted;

      const totalBrutReturnPercent = (totalBrutReturn / totalAccepted) * 100;

      const totalNetReturn = totalValue - orderedAcceptedValue;

      const totalNetReturnPercent = (totalNetReturn / orderedAcceptedValue) * 100;

     

      const totalReturnPercent = (totalReturn / orderedAcceptedValue) * 100;

    return (
        
        <div className="card card-full">
            <div className="card-body">
            <div className="card-top">
                    <div className="left">
                <h3 className="text-left">Uw overzicht</h3>
                {totalValue > 0 ?
               <p className="sub">Bijgewerkt op {profile[0].current_nav_date}</p>
               :
               <p className="sub">Vandaag bijgewerkt</p>
               }
                </div>
                <Link to="/portfolio" className="btn hide-phone"><i className="bi bi-wallet"></i> Bekijk details</Link>
                </div>
                {totalValue > 0 && (
                    <>
                
                <div className="investments">
                <div className='card w-100'>
                    <div className='card-body'>
                <h1 className="totalvalue">€<NumericFormat value={totalValue.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h1>
                <p className="muted small">Totale waarde</p>
                </div>
                </div>

                <hr className="little-line"/>

               <div className='card'>
                    <div className='card-body'>
                       <h5>€<NumericFormat value={totalAccepted.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                        <p className="muted small">Netto belegd</p>
                    </div>
                </div>
                
                <div className='card'>
                    <div className='card-body'>
                        <h5 className={totalBrutReturn > 0 && 'text-success'}>{totalBrutReturn > 0 && '+'}€<NumericFormat value={totalBrutReturn.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Behaald rendement</p>

                    </div>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <h5 className={totalBrutReturnPercent > 0 && 'text-success'}>{totalBrutReturnPercent > 0 && '+'}<NumericFormat value={totalBrutReturnPercent.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />%</h5>
                <p className="muted small">Behaald rendement</p>

                    </div>
                </div>


                {/* 

                    

                <div className='card'>
                    <div className='card-body'>
                       <h2>€<NumericFormat value={totalValue.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h2>
                        <p>Totale waarde</p>
                    </div>
                </div>
                */}
                </div>
                </>
                )}

                {totalOrdered > 0 && (
             
                <div className="orders">
                <Link className="order" to="/portfolio">
                <div className="tt_icon bg-beige hide-phone"><h5><i className="bi bi-clock"></i></h5></div>
                        <div className="left">
                            <h6>
                                In behandeling
                            </h6>
                            <p className="muted small">
                                Bekijk de status bij mijn beleggingen
                            </p>
                        </div>
                        <div className="right">
                            <h5>€<NumericFormat value={totalOrdered.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                        </div>
                    </Link>
                </div>
                

                )}

               
            </div>
        </div>
        
    )
}

export default Overview;


