import React from 'react';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Banner from './Banner';

const Login = () => {
    const { loginUser, wait, loggedInCheck } = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [Tfa, setTfa] = useState(false);
    const [Tfa_validation, setTfa_validation] = useState(false);
    const [resendFeedback, setResendFeedback] = useState(false); // State for visual feedback

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        tfa_code: ''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (!formData.email.trim() || !formData.password.trim()) {
            setErrMsg('Vul alle vereiste velden in. E-mail en wachtwoord zijn verplicht.');
            return;
        }

        const data = await loginUser(formData);
        if (data.success) {
            // Check if 2FA is enabled for the user
            if (data.success === 3) {
                // Show 2FA form
                setTfa(true);
                setTimeout(() => {
                    document.getElementById("tfa_code").focus();  // Focus the TFA input field
                }, 0);
            } else {
                // Proceed with the normal login flow
                e.target.reset();
                setRedirect('Redirecting...');
                await loggedInCheck();
                return;
            }
        }
        setErrMsg(data.message);
    };

    const submitTfaForm = async (e) => {
        e.preventDefault();

        if (!Object.values(formData).every(val => val.trim() !== '')) {
            setErrMsg('Vul alle vereiste velden in.');
            return;
        }

        const data = await loginUser(formData);
        if (data.success) {
            // Proceed with the normal login flow
            e.target.reset();
            setRedirect('Redirecting...');
            await loggedInCheck();
            return;
        }
        setErrMsg(data.message);
    };

    // Function to handle resending the 2FA code
    const resendTfaCode = async () => {
        setResendFeedback(true); // Start visual feedback
        // Call the function to resend the TFA code
        const response = await loginUser(formData); // Assuming this function can handle resending the code
        if (response.success) {
            setErrMsg('Een nieuwe verificatiecode is verzonden naar uw e-mail.');
        } else {
            setErrMsg(response.message);
        }
        // Reset feedback after a short delay
        setTimeout(() => {
            setResendFeedback(false);
        }, 2000);
    };

    return (
        <div className="splitter d-flex">
            <Banner />

            {Tfa ? (
                <div className="split form">
                    <div className="auth">
                        <h3 className="mb-1">Verificatie</h3>
                        <p className=" mb-3">Uw verificatiecode is verzonden via e-mail.</p>
                        <Form onSubmit={submitTfaForm}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-patch-check"></i></InputGroup.Text>
                                <Form.Control maxLength={6} pattern="\d{0,6}" type="text" name="tfa_code" onChange={onChangeInput} placeholder="6-cijferige code" id="tfa_code" value={formData.tfa_code} />
                            </InputGroup>

                            {errMsg && <div className="err-msg">{errMsg}</div>}
                            {redirect ? redirect : <button className="btn btn-primary" type="submit" disabled={formData.tfa_code.length !== 6}>Verifiëren</button>}
                        </Form>

                        {/* Resend code link with visual feedback */}
                        <div className="bottom-link" onClick={resendTfaCode} style={{ cursor: 'pointer', pointerEvents: resendFeedback ? 'none' : 'auto' }}>
                        <Link>  {resendFeedback ? 'Nieuwe code wordt verzonden...' : 'Ontvang een nieuwe code'} </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="split form">
                    <div className="ext">
                        <h6>Nieuw bij Capler?</h6>
                        <Link className="link" to="/signup">Meld je eerst aan</Link>
                    </div>
                    <div className="auth">
                        <h3>Inloggen</h3>
                        <Form onSubmit={submitForm}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-envelope"></i></InputGroup.Text>
                                <Form.Control type="email" name="email" onChange={onChangeInput} placeholder="E-mail" id="email" value={formData.email} required />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-lock"></i></InputGroup.Text>
                                <Form.Control type="password" name="password" onChange={onChangeInput} placeholder="Wachtwoord" id="password" value={formData.password} required />
                            </InputGroup>
                            {errMsg && <div className="err-msg">{errMsg}</div>}
                            {redirect ? redirect : <button className="btn btn-primary" type="submit" disabled={wait}>Inloggen bij Capler</button>}
                        </Form>

                        <div className="bottom-link"><Link to="/forgot">Wachtwoord vergeten?</Link></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Login;