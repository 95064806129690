
import React from "react";
import {useState,useContext,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Perbus from './Persbus';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Personaldata from './Personaldata';
import Partner from './Partner';
import Child from './Child';
import Businessdata from './Businessdata';
import Contactdata from './Contactdata';
import Completion from './Completion';
import Spinner from 'react-bootstrap/Spinner';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });
  


const Create = () => {

    const navigate = useNavigate();

    const {user, loggedInCheck} = useContext(UserContext);

    const [validated, setValidated] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [wait, setWait] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});

    const [progressTab, setProgressTab] = useState();

    const onChangeCreate = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    /* Set current tab based on local storage */

    const profileProgress = localStorage.getItem('profileProgress');

    const [currentTab, setCurrentTab] = useState(profileProgress || 'persbus');

    /* Define selected profile */
    
    const persBus = localStorage.getItem('persBus');

    const relation = localStorage.getItem('relation');

    const busName = localStorage.getItem('bus_name');

    const [enableState, setEnableState] = useState(false);

    const [personVerified, setPersonVerified] = useState(true);

    const [companySelected, setCompanySelected] = useState(true);

    const [partnerState, setPartnerState] = useState(false);

    const [childState, setChildState] = useState(false);

    const [businessState, setBusinessState] = useState(false);


    useEffect(() => {
      if (localStorage.getItem('persBus')) {
        setEnableState(true);
      }
        if (localStorage.getItem('persBus') === 'bus') {
            setBusinessState(true); 
            
            } else {
              setBusinessState(false);
            }
        if (localStorage.getItem('relation') === 'partner') {
          setPartnerState(true); 
          
          } else {
            setPartnerState(false);
          }
          if (localStorage.getItem('relation') === 'child') {
            setChildState(true); 
            
            } else {
              setChildState(false);
            }
      }, [persBus]);

      const onPersbusChange=(newValue)=>{
        if (newValue) {
        setEnableState(true);
        }
        if (newValue === 'pers') {
        localStorage.setItem('persBus', 'pers');
        localStorage.removeItem('relation');
        localStorage.removeItem('corAddress');
        
        } else {
        }
        if (newValue === 'bus') {
        localStorage.setItem('persBus', 'bus');
        localStorage.removeItem('relation');
        setBusinessState(true); 
        } else {
        setBusinessState(false);  
        }
        if (newValue === 'perspart') {
          localStorage.setItem('persBus', 'pers');
          localStorage.setItem('relation', 'partner');
          localStorage.removeItem('corAddress');
          setPartnerState(true);
        } else {
          localStorage.removeItem('joint');
          setPartnerState(false); 
        }
        if (newValue === 'perschild') {
          localStorage.setItem('persBus', 'pers');
          localStorage.setItem('relation', 'child');
          localStorage.removeItem('corAddress');
          setChildState(true);
        } else {
          localStorage.removeItem('joint');
          setChildState(false); 
        }
      
      }

      const onPersonVerified=(newValue)=>{
        if (newValue === true) {
        setPersonVerified(false);    
        } else {
        setPersonVerified(true);     
        }
      }

  

      const onCompanySelected=(newValue)=>{
        if (newValue) {
        localStorage.setItem('kvkNumber', newValue);
        setCompanySelected(false);    
        } else {
            setCompanySelected(true);     
        }
    }
    const [bus, setBus] = useState({
      bus_name:null
    });

   // Parent Component
const onChangeBusName = (e) => {
  const { name, value } = e.target;
  setBus({
    ...bus,
    [name]: value
  });
  localStorage.setItem('bus_name', value);
  if (value && value.length > 2) {
  setCompanySelected(false);
} else {
  setCompanySelected(true);  
}
}

    /* Handle contact form validation */

    const submitRef = useRef();

    const [contactValidated, setContactValidated] = useState(true);

    useEffect(() => {
        if ((contact.cor_phone != null && contact.cor_phone.length > 9) && ((contact.cor_street && contact.cor_no && (contact.cor_zip != null && contact.cor_zip.length >= 4)&& contact.cor_city && contact.cor_country) || (localStorage.getItem('corAddress') === 'kvk'))) {
            setContactValidated(false);   
            } else {
                setContactValidated(true)
                
            }
      });

   

    /* Get contact details from child */

    const [contact, setContact] = useState({
        cor_phone: null,
        cor_street: null,
        cor_no: null,
        cor_zip: null,
        cor_city: null,
        cor_country: null,
      });



      /* Handle child form validation */

    const childRef = useRef();

    const [childValidated, setChildValidated] = useState(true);
    useEffect(() => {
      if (child.child_firstname && child.child_lastname && child.child_bsn && child.child_date_of_birth) {
          setChildValidated(false);   
      } else {
          setChildValidated(true);
      }
  });

    /* Get questionnaire details from child */

    const [child, setChild] = useState({
      child_firstname: null,
      child_lastname: null,
      child_bsn: null,
      child_date_of_birth: null
      });



      /* Handle partner form validation */

    const partnerRef = useRef();

    const [partnerValidated, setPartnerValidated] = useState(true);

    useEffect(() => {
        if (partner.partner_firstname && partner.partner_lastname && partner.partner_email && (partner.partner_email !== user.email) && partner.partner_date_of_birth && partner.partner_bsn ) {
          setPartnerValidated(false);  
        } else if (partner.partner_email === user.email) { 
          setPartnerValidated(true);
         
            } else {
              setPartnerValidated(true);
            }
      });

   

    /* Get questionnaire details from child */

    const [partner, setPartner] = useState({
        partner_firstname: null,
        partner_lastname: null,
        partner_email: null,
        partner_date_of_birth: null,
        partner_bsn: null
      });


      /* Handle questionnaire form validation */

    const submitQn = useRef();

    const [qnValidated, setQnValidated] = useState(true);

    useEffect(() => {
        if (qn.qn_tax_country === 'NO' || (qn.qn_tax_country === 'YES' && qn.qn_tax_country_other)) {
            setQnValidated(false);   
            } else {
                setQnValidated(true);
            }
      });

   

    /* Get questionnaire details from child */

    const [qn, setQn] = useState({
        qn_amount_now: null,
        qn_amount_year: null,
        qn_horizon: null,
        qn_source_funds: null,
        qn_source_funds_other: null,
        qn_source_funds_country: null,
        qn_tax_country: null,
        qn_tax_country_other: null,
      });

/* Create profile */

    const handleCreateProfile = async (e) => {
      
    e.preventDefault();
    setQnValidated(true);
    const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      e.preventDefault();
      setValidated(true);
      
  
    if(!Object.values(formData).every(val => val.trim() !== '')){
        
        return;
    }
  
    const data = await createProfile(formData);
  
        e.target.reset();
    
  }
  
  const createProfile = async () => {
    
    setLoading(true);
    setWait(true);
    try{
      setQnValidated(true);
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        const {data} = await Axios.post('profile/create.php',{
          user_id: user.user_id,
          user_init: user.id_initials,
          user_first_name: user.id_first_name,
          user_last_name: user.id_last_name,
          pers_bus: localStorage.getItem('persBus'),
          profile_name: 'John',
          bus_coc: localStorage.getItem('kvkNumber'),
          cor_phone: contact.cor_phone,
          cor_street: contact.cor_street,
          cor_no: contact.cor_no,
          cor_zip: contact.cor_zip,
          cor_city: contact.cor_city,
          cor_country: contact.cor_country,
          qn_amount_now: qn.qn_amount_now,
          qn_amount_year: qn.qn_amount_year,
          qn_horizon: qn.qn_horizon,
          qn_source_funds: qn.qn_source_funds,
          qn_source_funds_other: qn.qn_source_funds_other,
          qn_source_funds_country: qn.qn_source_funds_country,
          qn_tax_country: qn.qn_tax_country,
          qn_us_person: qn.qn_us_person,
          qn_tax_country_other: qn.qn_tax_country_other,
          cor_address: localStorage.getItem('corAddress'),
          bus_name: localStorage.getItem('bus_name'),
          partner_firstname: partner.partner_firstname,
          partner_lastname: partner.partner_lastname,
          partner_bsn: partner.partner_bsn,
          partner_date_of_birth: partner.partner_date_of_birth,
          partner_email: partner.partner_email,
          child_firstname: child.child_firstname,
          child_lastname: child.child_lastname,
          child_bsn: child.child_bsn,
          child_date_of_birth: child.child_date_of_birth

        });

        if(data.success){
          localStorage.removeItem('persBus');
          localStorage.removeItem('kvkNumber');
          localStorage.removeItem('corAddress');
          localStorage.removeItem('profileProgress');
          localStorage.removeItem('setProgress');
          localStorage.removeItem('bus_name');
          localStorage.removeItem('relation');
          
          if (relation === 'child') {
            navigate('/identification?type=child');
          } else {
            navigate('/invest');
          }
          await loggedInCheck();
          
          setWait(false);
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
            setQnValidated(false);
        }
       
        
        
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  
    
  }


  
 

 /* Set tabs and save */

 const toPersbus = (eventKey) => {
    setCurrentTab("persbus");
    localStorage.setItem('profileProgress', 'persbus');
    setProgressTab(0);
    };

    const toPartner = (eventKey) => {
      setCurrentTab("partner");
      localStorage.setItem('profileProgress', 'partner');
      setProgressTab(1);
    };

    const toChild = (eventKey) => {
      setCurrentTab("child");
      localStorage.setItem('profileProgress', 'child');
      setProgressTab(2);
    };

    const toCompany = (eventKey) => {
      setCurrentTab("businessdata");
      localStorage.setItem('profileProgress', 'businessdata');
      setProgressTab(3);
    };

  const toPersonaldata = (eventKey) => {
    setCurrentTab("personaldata");
    localStorage.setItem('profileProgress', 'personaldata');
    setProgressTab(4);
  };

  const toContact = (eventKey) => {
    setCurrentTab("contactdata");
    localStorage.setItem('profileProgress', 'contactdata');
    setProgressTab(4);
  };

  const toCompletion = (eventKey) => {
    setCurrentTab("completion");
    localStorage.setItem('profileProgress', 'completion');
    setProgressTab(5);
    }
  
    const progressPercentage = (progressTab / 5) * 100;

    const getIconClass = (step) => {
      switch (step) {
          case 0:
              return 'bi-ui-checks-grid';
          case 1:
              return 'bi-people';
          case 2:
              return 'bi-diagram-2';
          case 3:
              return 'bi-briefcase';
          case 4:
              return 'bi-justify-left';
          case 5:
            return 'bi-person-vcard';
          default:
              return null;
      }
  };
    

    return (

      <>

<Card className="flowvest">
    <div className="progress-icons-container d-flex align-items-center justify-content-between">
        {[0, 1, 2, 3, 4, 5].map((step, index) => {
            // Define specific conditions for each step here
            const shouldRenderStep =
                (step === 0) || // Always render step 0
                (step === 1 && partnerState) || // Render step 1 only if partnerState is true
                (step === 2 && childState) || // Render step 2 only if progressTab is 2 or higher
                (step === 3 && businessState) || // Conditional render for step 3
                (step === 4) ||
                (step === 5) ; // Always render step 4 or add more conditions if needed

            if (!shouldRenderStep) return null; // Skip this step if the condition is not met

            return (
                <React.Fragment key={step}>
                    {/* Step Icon */}
                    <div className={`icon-container ${progressTab >= step ? 'completed' : ''}`}>
                        <i className={`bi ${getIconClass(step)}`} />
                    </div>

                    {/* Progress Bar (not shown after the last icon) */}
                    {index < 5 && (
                        <div className={`progress-bar-container ${progressTab > step ? 'filled' : ''}`}>
                            <div className="progress-bar"></div>
                        </div>
                    )}
                </React.Fragment>
            );
        })}
    </div>
</Card>
        
        <Tabs
        defaultActiveKey="persbus"
        activeKey={currentTab}
        id=""
        className="min-w-100 invest-flow"
        onSelect={(key) => setCurrentTab(key)}
        >
            
        <Tab eventKey="persbus" title={<span>{progressTab > 0 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Type profiel</span>} disabled>
        <div className="card card-full">
                        <div className="card-body">
            <Perbus onPersbusChange={onPersbusChange}/>

            <div className="card-btn d-flex">
               

               {user.has_profiles === 'yes' && (
                 <Link to='/invest/'
                 className="btn btn-link" 
                type="button"
                 >
                 Vorige
                 </Link>
               )}
                {persBus === 'pers' && relation === 'partner' ?
                <button
                onClick={toPartner}
                className="btn btn-primary" 
                disabled={!enableState}
                >
                Volgende
                </button>
                : persBus === 'pers' && relation === 'child' ?
                <button
                onClick={toChild}
                className="btn btn-primary" 
                disabled={!enableState}
                >
                Volgende
                </button>
                : persBus === 'pers' ?
                <button
                onClick={toContact}
                className="btn btn-primary" 
                disabled={!enableState}
                >
                Volgende
                </button>
                : 
                <button
                onClick={toCompany}
                className="btn btn-primary" 
                disabled={!enableState}
                >
                Volgende
                </button>
                }

               
            </div>
            </div>
            </div>
        </Tab>

        
        {partnerState && (

        <Tab eventKey="partner" title={<span>{progressTab > 1 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Gezamenlijk</span>}  disabled>
        <div className="card card-full">
                        <div className="card-body">
            
            <Partner partner={partner} setPartner={setPartner} partnerRef={partnerRef} />
            

            <div className="card-btn d-flex">
                <button
                onClick={toPersbus}
                className="btn btn-link" 
                
                >
                Vorige
                </button>
                <button
                onClick={toContact}
                className="btn btn-primary" 
                disabled={partnerValidated}
                >
                Volgende
                </button>
            </div>
            </div>
            </div>
        </Tab>

        )}


      {childState && (

      <Tab eventKey="child" title={<span>{progressTab > 2 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Minderjarige</span>}  disabled>
      <div className="card card-full">
                      <div className="card-body">
          
          <Child child={child} setChild={setChild} childRef={childRef} />
          

          <div className="card-btn d-flex">
              <button
              onClick={toPersbus}
              className="btn btn-link" 
              
              >
              Vorige
              </button>
              <button
              onClick={toContact}
              className="btn btn-primary" 
              disabled={childValidated}
              >
              Volgende
              </button>
          </div>
          </div>
          </div>
      </Tab>
      

      )}
        
        
        {businessState && (

        <Tab eventKey="businessdata" title={<span>{progressTab > 3 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Bedrijfsgegevens</span>}  disabled>
        <div className="card card-full">
                        <div className="card-body">
            
            <Businessdata bus={bus} setBus={setBus} onChangeBusName={onChangeBusName} onCompanySelected={onCompanySelected}/>
            

            <div className="card-btn d-flex">
                <button
                onClick={toPersbus}
                className="btn btn-link" 
                
                >
                Vorige
                </button>
                <button
                onClick={toContact}
                className="btn btn-primary" 
                disabled={companySelected}
                >
                Volgende
                </button>
            </div>
            </div>
            </div>
        </Tab>

        )}

        <Tab eventKey="contactdata" title={<span>{progressTab > 4 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Contactgegevens</span>} disabled>
        <div className="card card-full">
                        <div className="card-body">
            <Contactdata contact={contact} setContact={setContact} submitRef={submitRef} />
           
            <div className="card-btn d-flex">

            {persBus === 'pers' && relation === 'partner' ?
                <button
                onClick={toPartner}
                className="btn btn-link" 
       
                >
                Vorige
                </button>
                : persBus === 'pers' && relation === 'child' ?
                <button
                onClick={toChild}
                className="btn btn-link" 
           
                >
                Vorige
                </button>
                : persBus === 'pers' ?
                <button
                onClick={toPersbus}
                className="btn btn-link" 
     
                >
                Vorige
                </button>
                : 
                <button
                onClick={toCompany}
                className="btn btn-link" 
                >
                Vorige
                </button>
                }



                <button
                onClick={toCompletion}
                className="btn btn-primary" 
                disabled={contactValidated}
                >
                Volgende
                </button>
            </div>
            </div>
            </div>
        </Tab>

        <Tab eventKey="completion" title={<span>{progressTab > 5 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Beleggersprofiel</span>} disabled>
        <div className="card card-full">
                        <div className="card-body">
                        <Completion qn={qn} setQn={setQn} submitQn={submitQn}/>
                        {errMsg && <div className="err-msg">{errMsg}</div>}
                        <div className="card-btn d-flex">
                        
                        <button
                onClick={toContact}
                className="btn btn-link" 
               
                >
                Vorige
                </button>

                
                
                <Form className="large noinput" onSubmit={handleCreateProfile}>
                {loading ?
                <button
                className="btn btn-primary" 
                type=""
                disabled
                >
                  
                  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          
        /> 
                Profiel opslaan
                </button>
                :
                <button
                className="btn btn-primary" 
                type="submit"
                disabled={qnValidated}
                >
             
                Profiel opslaan
                </button>
                }

               
               
                </Form>
                        </div>
                        </div>
            </div>
        </Tab>
        
    </Tabs>
        
    </>
            

    )
}

export default Create;
