import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import {useState,useContext} from 'react';
import {UserContext} from './../context/UserContext'
import React from "react";
import MainForm from './identification/MainForm'
import PassFront from './identification/PassFront'
import Options from './identification/Options'
import PassBack from './identification/PassBack'
import BankNote from './identification/BankNote'
import Spinner from 'react-bootstrap/Spinner';
import {Button} from 'react-bootstrap';



const Identification = () => {

    const {user} = useContext(UserContext);

    const [isShown, setIsShown] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const idType = queryParams.get('type');

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);
    
        // 👇️ or simply set it to true
        // setIsShown(true);
      };

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

            <div className="board">
           

                {user.id_progress === 'PASSPORT_BACK' && idType !== 'child' ?

                 <div className="card card-large">
                 <div className="card-body">
                 
              <BankNote/>
              </div>
                </div>

                : user.id_progress === 'PASSPORT_FRONT' && idType !== 'child' ?

                <div className="card card-large">
                <div className="card-body">
                
             <PassBack/>
             </div>
               </div>

              : user.id_progress === 'PERSONAL_DATA' || idType === 'child'  ?

              <div className="card card-large">
              <div className="card-body">
              
           <PassFront/>
           </div>
             </div>

              :

            <>
              <div className="card card-large">
              <div className="card-body">
              
           <Options/>
           </div>
             </div>


            {/*

              <div className="card">
              <div className="card-body">
                
                <h4 className="text-left">Identificatie</h4>
                <p>Voordat u kunt beleggen, willen we uw identiteit controleren. Dit kan met een geldig paspoort of ID-kaart. De snelste methode is met behulp van uw mobiele telefoon. Mocht u deze niet hebben, kunt u een scan uploaden.</p>
                <div className="card-btn d-flex">
                    <button className="btn btn-primary" onClick={handleClick}>Begin met identificatie</button>
                </div>
                {isShown && <Verifai/>}

                </div>
                </div>
                */}
                </>
                
                }
              
            
            
            </div>
            
            
        </div>

    )
}

export default Identification;
