import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
});

const Signature = (props) => {
    const { user } = useContext(UserContext);
    const [errMsg, setErrMsg] = useState('');
    const [resendFeedback, setResendFeedback] = useState(false);
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [validated, setValidated] = useState(false);
    const [wait, setWait] = useState(false);
    const firstInputRef = useRef(null);
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        // Ensure the input is focused after component is mounted
        const focusInput = () => {
            if (firstInputRef.current) {
                firstInputRef.current.focus();
            }
        };

        // Set a small timeout in case of delayed rendering
        const timeoutId = setTimeout(focusInput, 0);

        // Clean up the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
      if (code.join('').length === 6) {
          submitTfaForm();
      }
  }, [code]); 

    const handleChange = (event, index) => {
      const newCode = [...code];
      newCode[index] = event.target.value;
  
      // Move focus to the next input
      if (event.target.value.length === 1 && index < 5) {
          const nextInput = document.getElementById(`digit-${index + 1}`);
          nextInput.focus();
      }
  
      setCode(newCode);
  
      // Submit after a short delay when the last digit is filled
      if (index === 5 && event.target.value.length === 1) {
          setTimeout(() => submitTfaForm(), 2400); // Delay to ensure state update completes
      }
  };

    const handleKeyDown = (event, index) => {
        // Move focus to the previous input on backspace
        if (event.key === 'Backspace' && index > 0 && code[index] === '') {
            const prevInput = document.getElementById(`digit-${index - 1}`);
            prevInput.focus();
        }
    };

    const submitTfaForm = async () => {
      const tfaCode = code.join('');
      
  
      
  
      try {
          const loginToken = localStorage.getItem('loginToken');
          Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
  
          const { data } = await Axios.post('investment/validate.php', { tfa_code: tfaCode, user_id: user.user_id });
  
          if (data.success) {
              props.onSuccess();
          } else {
              setErrMsg(data.reason);
          }
      } catch (error) {
          console.error('Error during submission:', error);
          setErrMsg('Er is iets misgegaan, probeer het opnieuw.');
      }
  };

      

     /* Create signature */

     const handleCreateSignature = async (e) => {
      setResendFeedback(true);
      e.preventDefault();
      setWait(true);
      const form = e.currentTarget;
        
        e.preventDefault();
        setValidated(true);
    
     
    
      const data = await createSignature();

      setTimeout(() => {
        // Reset resendFeedback after a delay
        setResendFeedback(false);
    }, 2000); // Adjust the delay as needed
      
      
    }

   

    const [payment, setPayment] = useState(null);
    
    const createSignature = async () => {
  
      
      try{
          const loginToken = localStorage.getItem('loginToken');
          Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
         
          const {data} = await Axios.post('signature/create.php',{
            user_id: user.user_id,
            investment_id_ext: params.get('id')
  
          }).then(response => response.data).then((d) => {
          
            //setUrl(d);
            //window.location.replace(d.Signers[0].SignUrl); // Move the redirect code here
          });
    
      }
      catch(err){
          setWait(false);
          return {success:0, message:'Server Error!'};
      }
    
      
    }


    return (
        <>
            <h4 className="text-left">Ondertekening</h4>
            <div>
                <p>U ondertekent middels tweestapsverificatie. Wij hebben u een unieke code verzonden via e-mail.</p>

                <Form className="mt-5" onSubmit={(e) => e.preventDefault()}>
                    <InputGroup className="mb-3 digit-field">
                        {code.map((digit, index) => (
                            <Form.Control
                                key={index}
                                id={`digit-${index}`}
                                type="text"
                                ref={index === 0 ? firstInputRef : null}
                                maxLength={1}
                                pattern="\d"
                                value={digit}
                                onChange={(event) => handleChange(event, index)}
                                onKeyDown={(event) => handleKeyDown(event, index)}
                                placeholder=""
                                autoComplete="off" 
                            />
                        ))}
                    </InputGroup>
                </Form>

                {errMsg && <p className="text-danger">{errMsg}</p>} {/* Display error message */}

                <div
                    className="bottom-link mw-fc"
                    onClick={handleCreateSignature}
                    style={{ cursor: 'pointer', pointerEvents: resendFeedback ? 'none' : 'auto' }}
                >
                    <Link className="small muted text-start mt-5 mw-fc">{resendFeedback ? 'Nieuwe code wordt verzonden...' : 'Ontvang een nieuwe code'}</Link>
                </div>
            </div>
        </>
    );
};

export default Signature;