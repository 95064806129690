import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MonthlyUpdate from '../../documents/MonthlyUpdate';

const UpdatePreview = () => {

    const { funds } = useContext(UserContext);
    const uniqueNavIdsPerProfile = [];
    


    return (
        
            <div className="card card-full">
                <div className="card-body">
                <div className="card-top">
                <div className="left">
                    <h3 className="text-left">Fondsupdates</h3>
                    <p className="sub mb-4">Bijgewerkt op {funds && funds[0]?.updates.slice(0, 1).map((filteredUpdate) => (
                   <>
                   {filteredUpdate.date_full}
                   </>
                ))}</p>
                 </div>
                <Link to="/documents/updates" className="btn hide-phone"><i className="bi bi-folder"></i> Updates</Link>
                </div>
                   
                    {funds && funds[0]?.updates.slice(0, 3).map((filteredUpdate) => (
                    <MonthlyUpdate
                        key={filteredUpdate.id}
                        updateId={filteredUpdate.id}
                        month={filteredUpdate.month}
                        year={filteredUpdate.year}
                        date={filteredUpdate.date}
                    />
                ))}
                   
                   
                    
                     
                </div>
            </div>
        
        
    )
}

export default UpdatePreview;


