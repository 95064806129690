import React from 'react';
import { Link } from 'react-router-dom';

const OpenAction = ({ title, description, link, icon }) => {
    return (
        <Link to={link} className="order action">
            <div className="tt_icon bg-beige hide-phone">
                <h5><i className={`bi ${icon}`}></i></h5>
            </div>
            <div className="left">
                <h6 className="h-grow">{title}</h6>
                <p className="muted small">{description}</p>
            </div>
            <div className="right">
                <i className="bi bi-arrow-up-right"></i>
            </div>
        </Link>
    );
};

export default OpenAction;