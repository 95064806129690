import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OpenAction from './OpenAction.js';

const OpenActions = () => {

    const {user, text, profile} = useContext(UserContext);
    
    // Check for investments with signature status not 'validated'
    const unvalidatedInvestments = profile.flatMap(profile =>
        profile.investments.filter(investment => investment.signature === 'pending')
    );

    // Check for orders with orders status not 'paid'
    const unpaidOrders = (profile || []).flatMap(profile => 
        (profile.investments || []).flatMap(investment => 
            (investment.orders || []).filter(order => order.order_status !== 'paid')
        )
    );


    return (
        
            <div className="card card-full">
                <div className="card-body">
                    <h4 className="text-left">Rond uw belegging af</h4>
                    {unvalidatedInvestments.length > 0 && (
                        <>
                            {unvalidatedInvestments.map(investment => (
                                <OpenAction
                                key={investment.investment_id}
                                title="Onderteken uw belegging"
                                description="Onderteken direct online om uw inschrijving af te ronden."
                                link={`/sign?id=${investment.investment_id_ext}`} // Adjust link as necessary
                                icon="bi-vector-pen"
                            />
                            ))}
                        </>
                    )}
                    {/*
                    {unpaidOrders.length > 0 && (
                        <>
                            {unpaidOrders.map(order => (
                                <OpenAction
                                key={order.order_id}
                                title="Betaal uw belegging"
                                description="Betaal direct online of bekijk de betaalgegevens in 'Mijn beleggingen'."
                                link={`/portfolio`} // Adjust link as necessary
                                icon="bi-credit-card"
                            />
                            ))}
                        </>
                    )}
                    */}
                    
                </div>
            </div>
        
        
    )
}

export default OpenActions;


