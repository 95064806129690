import React  from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

function BreadcrumbExample() {
  return (
    <>
      
      {window.location.href.includes('fund')
             ? 
             <Breadcrumb className="breadcrumbs">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/documents" }}>Mappen</Breadcrumb.Item>
                <Breadcrumb.Item active>Fonds</Breadcrumb.Item>
             </Breadcrumb>
             : window.location.href.includes('contracts')
             ? 
             <Breadcrumb className="breadcrumbs">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/documents" }}>Mappen</Breadcrumb.Item>
                <Breadcrumb.Item active>Inschrijvingen</Breadcrumb.Item>
             </Breadcrumb>
             : window.location.href.includes('mutations')
             ? 
             <Breadcrumb className="breadcrumbs">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/documents" }}>Mappen</Breadcrumb.Item>
                <Breadcrumb.Item active>Mutatieoverzichten</Breadcrumb.Item>
             </Breadcrumb>
              : window.location.href.includes('updates')
              ? 
              <Breadcrumb className="breadcrumbs">
                 <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/documents" }}>Mappen</Breadcrumb.Item>
                 <Breadcrumb.Item active>Updates</Breadcrumb.Item>
              </Breadcrumb>
             : 
             <Breadcrumb className="breadcrumbs">
                <Breadcrumb.Item active>Mappen</Breadcrumb.Item>
             </Breadcrumb>
           }
      
      </>
  );
}

export default BreadcrumbExample;