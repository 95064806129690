import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import {useState,useContext,useEffect} from 'react';
import {UserContext} from '../context/UserContext'
import React from "react";
import Form from 'react-bootstrap/Form'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Signature from './invest/Signature';
import Card from 'react-bootstrap/Card';
import { NumericFormat } from 'react-number-format';
import axios from 'axios'
export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });




const Sign = () => {

    const {user, profile, terms, loggedInCheck} = useContext(UserContext);

    const [lgShow, setLgShow] = useState(false);
    const [matchedProfile, setMatchedProfile] = useState(null);
    const [foundInvestment, setFoundInvestment] = useState(null);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [investmentIsChecked, setInvestmentIsChecked] = useState(false);
    const [contractIsChecked, setContractIsChecked] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [activeKey, setActiveKey] = useState('A'); 
    const [progressTab, setProgressTab] = useState();
    const [currentTab, setCurrentTab] = useState('checks');
    const [wait, setWait] = useState(false);
    const params = new URLSearchParams(window.location.search);

    const handleContract = () => setContractIsChecked(!contractIsChecked);
    const handleInvestment = () => setInvestmentIsChecked(!investmentIsChecked);

    const handleClose = () => {
        localStorage.removeItem('setProgress');
        return '/';
      }
      
      const handleNew = () => {
        localStorage.removeItem('setProgress');
        return '/invest';
      }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        if (params.has('id')) {
            const investmentIdExt = params.get('id');

            // Find profile with the investment that matches the `id` from the URL
            const profileWithInvestment = profile?.find(profile =>
                profile.investments.some(investment => investment.investment_id_ext === investmentIdExt)
            );

            // Set both profile and investment if found
            if (profileWithInvestment) {
                const investment = profileWithInvestment.investments.find(
                    investment => investment.investment_id_ext === investmentIdExt
                );
                setFoundInvestment(investment);
                setMatchedProfile(profileWithInvestment);
            }
        }

        

        if (terms[0]?.status === 'agreed') {
            setContractIsChecked(true);
        }

        setAllChecked(contractIsChecked && investmentIsChecked);
        return () => {
            const isOnSignPage = window.location.pathname.includes('/sign');

            if (!isOnSignPage) {
                loggedInCheck(); // Call the login check function
            } else {
            
            }
        };
    }, [profile, terms, contractIsChecked, investmentIsChecked]);

    const handleAccept = () => {
        setContractIsChecked(true);
        setLgShow(false);
    };

    const handleSuccess = () => {
        setCurrentTab("success");
        setProgressTab(2);
      };

    const progressPercentage = (progressTab / 5) * 100;

    const getIconClass = (step) => {
        switch (step) {
            case 0:
                return 'bi-list-check';
            case 1:
                return 'bi-patch-check';
            case 2:
                return 'bi-credit-card';
            default:
                return 'bi-circle';
        }
    };
    
    /* Create signature */

    const handleCreateSignature = async (e) => {
        e.preventDefault();
        setWait(true);
        const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          setValidated(true);
      
        if(!Object.values(formData).every(val => val.trim() !== '')){
            
            return;
        }
      
        const data = await createSignature(formData);
        if(data.success){
            e.target.reset();
            
            
        }
        else if(!data.success && data.message){
            
        }
        
      }

     

      const [payment, setPayment] = useState(null);
      
      const createSignature = async () => {
    
        
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
           
            const {data} = await Axios.post('signature/create.php',{
              user_id: user.user_id,
              investment_id_ext: params.get('id')
    
            }).then(response => response.data).then((d) => {
              setPayment(d);
              setCurrentTab("signature");
              setProgressTab(1);
              //setUrl(d);
              //window.location.replace(d.Signers[0].SignUrl); // Move the redirect code here
            });
      
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
      
        
      }

    return (

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
            <div className="board preview">

            <Card className="flowvest">
                <div className="progress-icons-container d-flex align-items-center justify-content-between">
                    {[0, 1, 2].map((step) => (
                        <React.Fragment key={step}>
                            {/* Step Icon */}
                            <div
                                className={`icon-container ${progressTab >= step ? 'completed' : ''}`}
                            >
                                <i className={`bi ${getIconClass(step)}`} />
                            </div>

                            {/* Progress Bar (not shown after the last icon) */}
                            {step < 2 && (
                                <div
                                    className={`progress-bar-container ${
                                    progressTab > step ? 'filled' : ''
                                    }`}
                                >
                                    <div className="progress-bar"></div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Card>

            <Tabs
                defaultActiveKey="checks"
                activeKey={currentTab}
                id=""
                className="min-w-100 invest-flow"
                onSelect={(key) => setCurrentTab(key)}
                >

            <Tab eventKey="checks" title="checks" disabled>

                <div className="card card-full">
                    <div className="card-body">
                        <h4>Onderteken uw belegging</h4>

                        <Form className="extra-large mt-0" onSubmit={handleCreateSignature}>   

                            {foundInvestment && matchedProfile && (
                            <>
                           <p>Onderstaande inschrijving is gedaan op {new Date(foundInvestment.investment_created_date).toLocaleDateString('nl-NL')}</p>
                            <div className="card card-sm mt-5 mb-4">
                                <div className="card-body">
                                <h6>Profiel</h6>
                                <p>{matchedProfile.profile_name} ({matchedProfile.pers_bus === 'pers' ? 'particulier' : 'zakelijk'})</p>
                                <br></br>
                                <h6>Type</h6>
                                <p>{foundInvestment.pension === 1 ? 'Pensioen' : 'Regulier'}</p>
                                <br></br>
                                <h6>Periodiciteit</h6>
                                <p>{foundInvestment.recurring === 1 ? 'Maandelijks' : 'Eenmalig'}</p>
                                <br></br>
                                <h6>Bedrag</h6>
                                <p>
                                <NumericFormat
                                value={foundInvestment.orders[0]?.order_amount}
                                displayType={'text'}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix="€"
                            />
                                </p>

                            <Form.Check className="mb-0 mt-4" type='checkbox'  id={`check-api-checkbox`}>
                                <Form.Check.Input 
                                    required
                                    value={investmentIsChecked}
                                    checked={investmentIsChecked} 
                                    type='checkbox' 
                                    onChange={handleInvestment}
                                    isValid={investmentIsChecked} />
                                <Form.Check.Label>{"Ik ga akkoord met de details van de bovenstaande inschrijving."} </Form.Check.Label>
                                
                            </Form.Check>

                                </div>
                            </div>
                            </>
                            )}
                            
                            {terms[0]?.status !== 'agreed' && (
                            <div className="card card-sm">
                                <div className="card-body">
                                
                                
                            <Button className="btn-grey btn-sm mb-3 mw-fc" onClick={() => setLgShow(true)}>Vernieuwde overeenkomst en voorwaarden <i class="bi ml-2 bi-arrow-up-right"></i></Button>
                            
                            <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                            className="terms-modal"
                            >
                            <Modal.Header closeButton>
                                <div>
                                <h4 className="mb-0">
                                Overeenkomst en voorwaarden
                                </h4>
                                <p className="small muted">{terms[0]?.content?.terms_effective_date} (v{terms[0]?.content?.terms_version})</p>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                            
                            
                            <Accordion activeKey={activeKey}>
                        {terms[0]?.content?.sections.map((section) => (
                            <Accordion.Item key={section.section_id} eventKey={section.section_id}>
                                <Accordion.Header>
                                    <Button variant="link" onClick={() => setActiveKey(activeKey === section.section_id ? null : section.section_id)}>
                                    {section.section_id}. {section.title}
                                    </Button>
                                </Accordion.Header>
                                <Accordion.Body>
                                <ol>
                                    {section.clauses.map((clause) => (
                                        <>
                                            <li key={clause.clause_id}>{clause.text}</li>
                                            {clause.order && clause.order.length > 0 && (
                                                <ol type="I">
                                                    {clause.order.map((subpoint) => (
                                                        <li key={subpoint.subsection_id}>
                                                            {subpoint}
                                                        </li>
                                                    ))}
                                                </ol>
                                            )}
                                            {clause.subpoints && clause.subpoints.length > 0 && (
                                                <ul>
                                                    {clause.subpoints.map((subpoint) => (
                                                        <li key={subpoint.subsection_id}>
                                                        {subpoint}
                                                    </li>
                                                    ))}
                                                </ul>
                                            )}
                                            </>
                                    ))}
                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                        </Accordion>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button
                            onClick={() => setLgShow(false)}
                            className="btn btn-link"
                            
                            >
                            Sluiten
                            </Button>
                            <Button
                            onClick={handleAccept}
                            ><i className="bi bi-check-square mr-3"></i> Accepteren</Button>
                            </Modal.Footer>
                            </Modal>
                                
                                <Form.Check className="mb-0" type='checkbox' id={`check-api-checkbox`}>
                                <Form.Check.Input 
                                    required
                                    value={contractIsChecked}
                                    checked={contractIsChecked} 
                                    type='checkbox' 
                                    onChange={handleContract}
                                    isValid={contractIsChecked} />
                                <Form.Check.Label>{"Ik ga akkoord met de overeenkomst en voorwaarden."} </Form.Check.Label>
                                
                                </Form.Check>
                            
                                </div>
                            </div>

                          )}
                
                            <div className="card-btn d-flex mt-5">
                            <button
                            className="btn btn-primary" 
                            type="submit"
                            disabled={!allChecked}
                            >
                            Verder naar ondertekening
                            </button>
                            </div>

                          

                           
                       
                        </Form>

                        </div>

                        </div>

            </Tab>

            <Tab eventKey="signature" title="signature" disabled>
            <div className="card card-full">
            <div className="card-body">
            <Signature onSuccess={handleSuccess} />
            </div>
            </div>

            </Tab>

            <Tab eventKey="success" title="success" disabled>
            <div className="card card-full">
            <div className="card-body">
            <h3>Ondertekening voltooid</h3>
            <p>Bedankt! We hebben uw handtekening ontvangen. Heeft u nog vragen? Laat het ons weten!</p>

            <div className="spacer s mt-3"></div>

            <div className="card-btn d-flex">
                <Form className="full ml-auto mt-3" onSubmit={handleClose}>
                            
                <button
              
                className="btn mt-3 ml-auto float-end btn-link" 
                type="submit"

                >
                Afsluiten
                </button>
               </Form>
               <Form className="full ml-auto mt-3" onSubmit={handleNew}>
                            
                <button
              
                className="btn mt-3 ml-auto float-end btn-primary" 
                type="submit"

                >
                Nog een inschrijving doen
                </button>
               </Form>
                 
              
            </div>

            </div>
            </div>

            </Tab>

            </Tabs>

                    </div>
                       
                  
  
            </div>  
           
            

    )
}

export default Sign;
