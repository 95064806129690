import React  from 'react';
import {useContext, useState} from 'react'
import {UserContext} from '../../context/UserContext'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Contract from '../../documents/Contract'
import { NumericFormat } from 'react-number-format';

const FinalChecks = (props) => {

    const {profile, text, terms} = useContext(UserContext);

    const selectedProfile = localStorage.getItem('selectedProfile');

    const brutAmount = localStorage.getItem('brutAmount');

    const selectedMethod = localStorage.getItem('selectedMethod');

    const selectedRecurring = localStorage.getItem('selectedRecurring');

    const setMonthlyAmount = localStorage.getItem('setMonthlyAmount');

    const [lgShow, setLgShow] = useState(false);
 
    const [activeKey, setActiveKey] = useState('A'); 
  
    const handleAccept = () => {
      props.setContractIsChecked(true); // Assuming this function is passed down to update contractIsChecked
      setLgShow(false); // Close the modal
  };
    

    return (
        
<>
                <h4 className="text-left">Een laatste check</h4>
              
                

                <div>
        {profile.filter(profile => profile.profile_id === selectedProfile).map(filteredProfile => (
   <>
   <p>Controleer of onderstaande gegevens kloppen.</p>
  
    <Form className="extra-large">
    
      {['checkbox'].map((type) => (
        <>
        <div key={type} className=" mt-3">
        <div className="card card-sm mt-5">
        <div className="card-body">
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.profileIsChecked}
                type={type} 
                onChange={props.handleProfile}
                isValid={props.profileIsChecked} 
            />
            <Form.Check.Label>{"Ik participeer als "+(filteredProfile.profile_name)+'.'} </Form.Check.Label>
            
          </Form.Check>

          
          {localStorage.getItem('brutAmount') && 
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.amountIsChecked}
                type={type} 
                onChange={props.handleAmount}
                isValid={props.amountIsChecked} />
            <Form.Check.Label>{"Ik beleg "+(selectedRecurring && selectedRecurring === 'yes' ? 'maandelijks €' : 'nu eenmalig €')}<NumericFormat value={brutAmount} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />.</Form.Check.Label>
           
          </Form.Check>
          }

          {localStorage.getItem('selectedMethod') && 
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.methodIsChecked}
                type={type} 
                onChange={props.handleMethod}
                isValid={props.methodIsChecked} />
            <Form.Check.Label>{"Ik beleg "+(selectedMethod === 'regular' ? 'niet volgens aanvullende voorwaarden lijfrente.' : 'voor mijn pensioen via een lijfrente en ben goed ingelicht over de fiscale voor- en nadelen.')} </Form.Check.Label>
           
          </Form.Check>
            }
           </div>
          </div>
        </div>
        {terms[0]?.status !== 'agreed' && (
        <div className="card card-sm mt-4">
          <div className="card-body">
           
           
      <Button className="btn-grey btn-sm mb-3 mw-fc" onClick={() => setLgShow(true)}>Vernieuwde overeenkomst en voorwaarden <i class="bi ml-2 bi-arrow-up-right"></i></Button>
      
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="terms-modal"
      >
        <Modal.Header closeButton>
          <div>
          <h4 className="mb-0">
            Overeenkomst en voorwaarden
            </h4>
            <p className="small muted">{terms[0]?.content?.terms_effective_date} (v{terms[0]?.content?.terms_version})</p>
            </div>
        </Modal.Header>
        <Modal.Body>
       
       
        <Accordion activeKey={activeKey}>
    {terms[0]?.content?.sections.map((section) => (
        <Accordion.Item key={section.section_id} eventKey={section.section_id}>
            <Accordion.Header>
                <Button variant="link" onClick={() => setActiveKey(activeKey === section.section_id ? null : section.section_id)}>
                {section.section_id}. {section.title}
                </Button>
            </Accordion.Header>
            <Accordion.Body>
            {section.introduction ? <p>{section.introduction}</p> : ''}
            <ol>
                {section.clauses.map((clause) => (
                  <>
                        <li key={clause.clause_id}>{clause.text}</li>
                        {clause.order && clause.order.length > 0 && (
                          <ol type="I">
                              {clause.order.map((subpoint) => (
                                  <li key={subpoint.subsection_id}>
                                      {subpoint}
                                  </li>
                              ))}
                          </ol>
                      )}
                        {clause.subpoints && clause.subpoints.length > 0 && (
                          <ul>
                              {clause.subpoints.map((subpoint) => (
                                  <li key={subpoint.subsection_id}>
                                  {subpoint}
                                </li>
                              ))}
                          </ul>
                      )}
                      </>
                ))}
                </ol>
            </Accordion.Body>
        </Accordion.Item>
    ))}
</Accordion>
</Modal.Body>
<Modal.Footer>
<Button
                           onClick={() => setLgShow(false)}
                            className="btn btn-link"
                            
                            >
                            Sluiten
                            </Button>
        <Button
        onClick={handleAccept}
        ><i className="bi bi-check-square mr-3"></i> Accepteren</Button>
      </Modal.Footer>
      </Modal>
            
            <Form.Check className="mb-0" type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.contractIsChecked}
                checked={props.contractIsChecked} 
                type={type} 
                onChange={props.handleContract}
                isValid={props.contractIsChecked} />
            <Form.Check.Label>{"Ik ga akkoord met de overeenkomst en voorwaarden."} </Form.Check.Label>
           
          </Form.Check>
        
          </div>
        </div>
        )}
        </>
      ))}
    </Form>

    
    

    </>
  ))}
</div>

</>
        
    )
}

export default FinalChecks;


