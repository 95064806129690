import React  from 'react';
import {useState,useContext,useEffect} from 'react';
import {UserContext} from '../../context/UserContext'
import { NumericFormat } from 'react-number-format';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import Redirecting from '../../components/Redirecting';
import Form from 'react-bootstrap/Form';
export const Axios = axios.create({
  baseURL: 'https://admin.capler.nl/',
});




const ProfileWallets = ({participatedProfile, pension, handleCreatePayment}) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [checkout, setCheckout] = useState([]);
    const [validated, setValidated] = useState(false);
    const [wait, setWait] = useState(false);
    const [url, setUrl] = useState([]);
    const [payment, setPayment] = useState([]);
    const [formData, setFormData] = useState({});

    const openOrders = participatedProfile.investments[0]?.orders.filter(order => order.order_status === 'open');
const openOrdersAlt = participatedProfile.investments[1]?.orders.filter(order => order.order_status === 'open');
const defaultActiveKey = openOrders && openOrders.length > 0 ? openOrders[0]?.order_id : openOrdersAlt && openOrdersAlt.length > 0 ? openOrdersAlt[0]?.order_id : null;


    const {user, text, funds} = useContext(UserContext);

    var valueAmount = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_value_amount 
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_value_amount
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_value_amount
    : "" ;

    var valueAmountPart = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_value_amount_part 
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_value_amount_part
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_value_amount_part
    : "" ;

    var profileOrderedAccepted = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_ordered_accepted
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_ordered_accepted
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_ordered_accepted
    : "" ;

    var profileAccepted = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_accepted_amount
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_accepted_amount
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_accepted_amount
    : "" ;

    var profileBrutReturn = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_total_return
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_total_return
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_total_return
    : "" ;

    const profileBrutReturnPercent = (profileBrutReturn / profileAccepted) * 100;

    const profileNetReturn = valueAmount - profileOrderedAccepted;

    const profileNetReturnPercent = (profileNetReturn / profileOrderedAccepted) * 100;

    function addMoney(e) {
        e.preventDefault();
        localStorage.setItem('setProgress', 'profile');
        localStorage.setItem('selectedMethod', 'regular');
        localStorage.setItem('selectedProfile', e.target.dataset.letter);
        navigate("/invest")
      };

     

    return (
        
       
        <div className="card card-full">
            
            <div className="card-body">
            <div className="card-top">
                    <div className="left">
                <h4 className="text-left">{participatedProfile.profile_name}</h4>
               {participatedProfile.pers_bus === 'pers' && pension === 'no' ?
               <p className="sub">Privé beleggen</p>
               : participatedProfile.pers_bus === 'pers' && pension === 'yes' ?
               <p className="sub">Pensioen beleggen</p>
                : <p className="sub">Zakelijk beleggen</p>
                }
                </div>
               
                <Link key={participatedProfile} data-letter={participatedProfile.profile_id} onClick={addMoney} className="btn hide-phone"><i className="bi bi-plus"></i> Nieuwe belegging</Link>
            </div>


       {valueAmount > 0 && (
        
                <>
               
                <div className="investments">
                    
                <div className='card w-100'>
                    <div className='card-body'>
                        
               
                 <h1>€<NumericFormat value={valueAmount.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h1>
                
                
                
                <p className="muted small">Totale waarde</p>

                    </div>
                </div>

                <hr className="little-line"/>
                
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 >€<NumericFormat value={profileAccepted.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Netto belegd</p>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileBrutReturn > 0 && 'text-success'}>{profileBrutReturn > 0 && '+'}€<NumericFormat value={profileBrutReturn.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Behaald rendement</p>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileBrutReturnPercent > 0 && 'text-success'}>{profileBrutReturnPercent > 0 && '+'}<NumericFormat value={profileBrutReturnPercent.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />%</h5>
                <p className="muted small">Behaald rendement</p>

                    </div>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        
                <h5 >€<NumericFormat value={profileOrderedAccepted.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Totaal belegd</p>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileNetReturn > 0 ? 'text-success' : 'text-danger'}>{profileNetReturn > 0 && '+'}€<NumericFormat value={profileNetReturn.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Totaal rendement</p>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileNetReturnPercent > 0 ? 'text-success' : 'text-danger'}>{profileNetReturnPercent > 0 && '+'}<NumericFormat value={profileNetReturnPercent.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />%</h5>
                <p className="muted small">Totaal rendement</p>

                    </div>
                </div>

                </div>

                <div className="investments">
                    


                    <div className='card'>
                    <div className='card-body'>
                        
                <h5><NumericFormat value={valueAmountPart.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Aantal participaties</p>

                    </div>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        
                <h5>{profileNetReturn > 0 && '+'}€<NumericFormat value={funds[0].current_nav.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Huidige waarde per participatie</p>

                    </div>
                </div>


                </div>

                </>
        
       )}
        {participatedProfile.profile_ordered_amount > 0 && (

<div className="orders">
<Accordion defaultActiveKey={defaultActiveKey} flush>
                 {participatedProfile.investments.slice().reverse().map((selectedInvestment, indexy) => (
                    <>
                    {((selectedInvestment.pension === '0' && pension === 'no') || (selectedInvestment.pension === '1' && pension === 'yes')) && (
                    <>
                     {selectedInvestment.orders.slice().reverse().map((selectedOrder, indexie) => (
                    <>
                    {selectedOrder.order_status && selectedOrder.order_status !== 'accepted'  && (
                <>
                
                 <Accordion.Item key={selectedOrder.order_id} eventKey={selectedOrder.order_id}>
                 <Accordion.Header>
                <div className="order">
                        
                        {selectedOrder.order_status === 'approved' ?
                            <div className="tt_icon bg-grurple hide-phone">
                              <h5><i className="bi bi-check-all"></i></h5>
                            </div>
                            : selectedOrder.order_status === 'paid' ?
                            <div className="tt_icon bg-grurple hide-phone">
                              <h5><i className="bi bi-check"></i></h5>
                            </div>
                            :
                            <div className="tt_icon bg-beige hide-phone">
                              <h5><i className="bi bi-clock"></i></h5>
                            </div>
                            }
                       
                        <div className="left">
                        
                            {selectedOrder.recurring === 'yes' ?
                             <h6>Maandelijkse belegging van {selectedOrder.order_created_date}</h6>
                            :
                            <h6>Eenmalige belegging van {selectedOrder.order_created_date}</h6>
                            }
                           
                            
                            
                                
                            
                           
                            {selectedOrder.order_status === 'approved' ?
                            <p className="muted small">Wordt verwerkt bij volgende uitgifte</p>
                            : selectedOrder.order_status === 'paid' ?
                            <p className="muted small">Inschrijving compleet & betaling ontvangen.</p>
                            : selectedOrder.order_status === 'open' ?
                            <p className="muted small">Transactie nog niet bevestigd. Zie betaalgegevens.</p>
                            :
                            <p className="muted small">In behandeling</p>
                            }
                           
                        </div>
                        <div className="right">
                            <h5>€<NumericFormat value={selectedOrder.order_amount.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                        </div>
                    </div>
               
                   
                   </Accordion.Header>
                   {selectedOrder.order_status === 'paid' ?
                   <Accordion.Body>
                    
                   <div className="card">
                   <div className="card-body">
                    <h6 className="mb-3">Inschrijving compleet & betaling ontvangen</h6>
                     <p className="">Elke eerste werkdag van de maand start het proces om inschrijvingen om te zetten in participaties. Dit kan tot 28 werkdagen duren. U ontvangt bericht van ons zodra uw inschrijving is geaccepteerd. {/* Uiterlijk {selectedOrder.order_acceptance_date}. */}</p>         
                          
                   </div>
                   </div>
         
         
                                     
         
                 </Accordion.Body>
                   : selectedOrder.order_status === 'open' ?
                   <Accordion.Body>

{selectedOrder.recurring === 'yes' ?
                              <div className="card">
                              <div className="card-body">
                    
                                <h6 className="mb-3">Gegevens periodieke overboeking</h6>         
                                <p className="small muted mb-1 mt-2">Bankrekeningnummer:</p> <p>NL25 RABO 0375 545 387</p>
                                <p className="small muted mb-1 mt-2">Ten name van:</p> <p>Stichting Alpha High Performance Fund - Capler PE</p>
                                <p className="small muted mb-1 mt-2">Onder vermelding van uw contractnummer:</p> <p>REC-{selectedInvestment.investment_id_ext}</p>
       
                              
      
                              </div>
                              </div>
                            :
                            
                            <div className="card">
                            <div className="card-body">
                  
                              <h6 className="mb-3">Details handmatige overboeking</h6>         
                              <p className="small muted mb-1 mt-2">Bankrekeningnummer:</p> <p>NL25 RABO 0375 545 387</p>
                              <p className="small muted mb-1 mt-2">Ten name van:</p> <p>Stichting Alpha High Performance Fund - Capler PE</p>
                              <p className="small muted mb-1 mt-2">Onder vermelding van uw ordernummer:</p> <p>{selectedOrder.order_id_ext}</p>
                  
                              {selectedOrder.order_amount < 50000 && (
                  
                              <Form className="full mt-3 ml-auto" onSubmit={(e) => handleCreatePayment(e, selectedOrder.order_id, selectedOrder.order_id_ext, selectedOrder.order_amount)}>
                                              
                                              <button
                                              className="btn mt-3  btn-primary" 
                                              type="submit"
                              
                                              >
                                              Direct online betalen
                                              </button>
                                              </Form>    
                            )}
                            </div>
                            </div>

                            }
                 
         

        

                            

        </Accordion.Body>
        :
        <></>
        }
        <hr/>
                   </Accordion.Item>
                  
                   </>
           
                  )}
                </>

              
                 ))}
                 </>
                   )}
                   </>
            ))}
             </Accordion>
             </div>
            
           
     
    )
    }
   
</div>
    </div>
   

    )


}

export default ProfileWallets;