import React from "react";
import {useContext, useEffect, setState} from 'react'
import {UserContext} from '../../context/UserContext'
import {useNavigate} from 'react-router-dom';

const Loading = () => {


    const {loggedInCheck, user} = useContext(UserContext);
    const navigate = useNavigate();
    const loadingTitle = "world";
    const loadingText = "world";



    useEffect(() => {
        loggedInCheck(loadingTitle, loadingText);
        setTimeout(() => {
        
        if(user.id_progress && user.id_progress !== 'PROCESSING'){
          
        
                navigate('/identification/result');
            
        
        }
        }, 5000);
        let intervalId = setInterval(loggedInCheck, 20000);
        return(() => {
            clearInterval(intervalId)
        })
       
        
        }, []);



  return (
    
  
   <></>

  )
}

export default Loading;

