import React  from 'react';
import {useContext} from 'react';
import {UserContext} from '../../context/UserContext'
import { Link } from 'react-router-dom';

const InboxItem = () => {

    const {notifications, text} = useContext(UserContext);


    
    const numDescending = [...notifications].sort((a, b) => b.order_date - a.order_date);


    return (
        
        <div className="card card-small hide-medium">
            <div className="card-body">
            <div className="card-top">
                <h3 className="text-left">{text.messages}</h3>
                <Link to="/inbox" className="btn"><i className="bi bi-inbox"></i> Inbox</Link>
            </div>
                {numDescending.slice(0, 3).map((notifications, idx) => { 

                  
                        
return(
    <Link className="nav-link" key={idx} to={ '/inbox/' + notifications.string } >
    <h6 className="h-grow">{notifications.subject}</h6>
    <h6 className="small muted">{notifications.preview_date}</h6>
    <p class="small muted">{notifications.body}</p>
    
    </Link>
    )
})}

            </div>
        </div>
        
    )
}

export default InboxItem;


